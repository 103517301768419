<template>
  <div>
    <div class="header">
      <h4>企业客户管理</h4>
    </div>
    <ListFilter :total="total" @close="putAway">
      <div class="summary">
        <p>关键词：</p>
        <el-input
          v-model="texts"
          placeholder="请输入内容"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          @change="getSearch()"
        ></el-input>
      </div>
    </ListFilter>
    <div>
      <el-table :data="tableData" style="width: 100%；">
        <el-table-column fixed label="企业名称" align="center">
          <template scope="scope">
            <div
              style="
                color: #409eff;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="getMore(scope.row)"
            >
              <span v-if="scope.row.enterprise_name">
                {{ scope.row.enterprise_name }}</span
              >
              <span v-else>{{ scope.row.agententerprise_code }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="contract_name" label="合同名称">
        </el-table-column> -->
        <el-table-column
          prop="enterprise_idCardNumber"
          label="统一信用代码"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column prop="idCardImage" label="营业执照" align="center">
          <template slot-scope="scope">
            <el-popover placement="right-start" title="图片" trigger="hover">
              <img
                :src="scope.row.idCardImage"
                alt=""
                srcset=""
                style="width:120px;height:80px;"
              />
              <span
                slot="reference"
                trigger="hover"
                class="el-icon-postcard font-color-brand"
                style="cursor: pointer"
                
                >营业执照</span
              >
            </el-popover>
         
          </template>
          
        </el-table-column> -->
        <!-- <el-table-column
          prop="contract_signDeadline"
          label="合同有效期"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="contract_state"
          label="合同签署状态"
          align="center"
        >
          <template scope="scope">
            <el-tag v-if="scope.row.contract_state === '1'" type="info"
              >草稿</el-tag
            >
            <el-tag v-if="scope.row.contract_state === '2'" type="primary"
              >发起中</el-tag
            >
            <el-tag v-if="scope.row.contract_state === '3'" type="success"
              >签署中</el-tag
            >

            <el-tag v-if="scope.row.contract_state === '4'" type="success"
              >签署成功</el-tag
            >
            <el-tag v-if="scope.row.contract_state === '5'" type="danger"
              >签署失败</el-tag
            >
            <el-tag v-if="scope.row.contract_state === '6'" type="danger"
              >签署过期</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column prop="agentuser_name" label="维护人员" align="center">
        </el-table-column>

        <el-table-column
          prop="agententerprise_name"
          label="维护企业"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >更换服务人员</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="更换维护人员" :visible.sync="outerVisible">
        <div class="dialog-top">
          <el-row :gutter="24" style="width: 100%">
            <el-col :span="12" class="text-align">
              <div>
                <span>当前维护的企业：</span>
                <span>{{ changeEnterprise }}</span>
              </div>
            </el-col>
            <el-col :span="12" class="text-align">
              <div>
                <span>当前维护人员：</span> <span>{{ maintenanceStaff }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="dialog-center">
          <el-row :gutter="24" style="width: 100%">
            <el-col :span="12" class="text-align">
              <div>
                <span> 更换维护企业名称：</span>
                <el-select
                  v-model="form.agentEnterpriseCode"
                  placeholder="请选择"
                  filterable
                  :filter-method="gosearch"
                  size="mini"
                  @focus="getenterprise()"
                  @change="enterprisesChange()"
                >
                  <el-option
                    :disabled="form.enterpriseCode === item.code"
                    v-for="(item, index) in enterpriseList"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" class="text-align">
              <div>
                <span>更换维护人员姓名：</span>
                <el-select
                  v-model="form.agentUserCode"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="item in personnelList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>

        <div slot="footer" class="dialog-footer">
          <div class="btn-border">
            <el-button type="primary" @click="replacement()"
              >确认更换</el-button
            >
            <el-button @click="cancel()">取 消</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNumber"
      :page-size="pageSize"
      layout="prev,pager, next,jumper, sizes"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const request = api('/enterprise');
export const maintenanceList = api()('user.describe.subuser.json');
export const enterpriseLisrt = api()('enterprise.list.json');
export const enterpriseEdit = api()('enterprise.edit.json');
export default {
  data() {
    return {
      form: {
        enterpriseCode: '',
        agentEnterpriseCode: '',
        agentUserCode: ''
      },
      texts: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      changeEnterprise: '',
      maintenanceStaff: '',
      personnel: '',
      keywords: '',
      personnelList: [
        {
          value: '王小波1',
          bhno: '252251'
        },
        {
          value: '王小波2',
          bhno: '2'
        },
        {
          value: '王小波3',
          bhno: '3'
        },
        {
          value: '王小波4',
          bhno: '4'
        }
      ],
      enterpriseList: [
        {
          value: '西安零工保信息技术有限公司'
        },
        {
          value: '陕西阔成检测服务有限公司'
        },
        {
          value: '新疆零工保'
        },
        {
          value: '其他'
        }
      ],
      tableData: [],
      outerVisible: false
    };
  },
  created() {
    this.loadData();
  },
  components: {
    ListFilter: () => import('/src/components/filter')
  },

  methods: {
    handleSelect(name) {
      console.log(name);
    },
    getSearch() {
      this.pageNumber = '1';
      this.loadData();
    },
    async getmaintenance() {
      console.log(this.form.agentEnterpriseCode);
      const params = {
        keywords: '',
        enterprise: this.form.agentEnterpriseCode,
        pageNumber: 1,
        pageSize: 1000,
        roles: 'enterprise_agent_user'
      };
      await maintenanceList(params).then(res => {
        console.log(res.list);
        this.personnelList = res.list;
      });
    },
    async getenterprise(keywords) {
      const params = {
        keywords: keywords,
        pageNumber: 1,
        pageSize: 20
      };
      await enterpriseLisrt(params).then(res => {
        console.log(res.list);
        this.enterpriseList = res.list;
      });
    },
    loadData() {
      const params = {
        keywords: this.texts,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      };
      request('agent.sub.enterprise.list.json')(params).then(res => {
        this.tableData = res.list;
        this.total = res.total;
        // this.enterpriseList = res.list;
      });
    },
    gosearch(keywords) {
      console.log(keywords);
      this.getenterprise(keywords);
    },
    enterprisesChange() {
      this.form.agentUserCode = '';
      this.getmaintenance();
    },
    putAway() {
      this.texts = '';
      this.pageNumber = 1;
      this.loadData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    handleClick(row) {
      this.outerVisible = true;
      this.changeEnterprise = row.enterprise_name;
      this.maintenanceStaff = row.agentuser_name;
      this.form.enterpriseCode = row.enterprise_code;
      // this.form.agentEnterpriseCode =row.enterprise_code;
      this.form.agentUserCode = '';
    },
    getMore(row) {
      this.$router.push({
        path: '/system/enterprise/detail',
        query: {
          code: row.enterprise_code
        }
      });
    },
    async replacement() {
      if (!this.form.agentEnterpriseCode || !this.form.agentUserCode) {
        return this.$message.error('请先选择要更换的企业和业务人员');
      }
      await enterpriseEdit({
        code: this.form.enterpriseCode,
        agentEnterprise: this.form.agentEnterpriseCode,
        agentUser: this.form.agentUserCode
      });
      this.outerVisible = false;
      this.loadData();
    },
    cancel() {
      this.outerVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.summary {
  display: flex;
  align-items: center;
}
.btn {
  padding: 5px 20px;
  //   border: 1px solid rgb(0 0 0);
  background: rgb(242 242 242);
  border-radius: 4px;
  margin: 0 20px;
}
.dialog-top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-center {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-border {
  display: flex;
  justify-content: flex-end;
}
</style>
